<template>
    <div class="p-2 px-3">
        <div class="h-100 flex-fill d-flex flex-column">

            <div class="">
                <router-link to="/bussiness" class="h4 mb-0 text-white d-flex align-items-center" style="">
                    <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                    Back
                </router-link>
            </div>

            <h3 class="text-center font-weight-bolder text-white mt-2">Pet Sitting Form</h3>

            <hr style="border-color: #FFAD32;" class="w-100 " /> 

            <div class="mt-2">
                <label class="h3 text-white">Location</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-map"></i></span>
                    <input class="form-control" placeholder="Location" v-model="location">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.location && isSubmit" style="margin-top: .5em;">Trainer Speciality is not valid</div>
            </div>

            <div class="mt-2">
                <label class="h3 text-white">Pet Type</label>
                <div class="d-flex" style="gap:10px 15px">
                    <div class="input-group" >
                        <select class="form-control" v-model="preferred_pet" style="background: transparent;border:unset;border-bottom: 1px solid white;border-radius: unset;">
                            <option value="dog">Dogs</option>
                            <option value="cat">Cats</option> 
                        </select>
                    </div>
                </div> 
                <div class="badge badge-danger text-white" v-if="!valid.preferred_pet && isSubmit" style="margin-top: .5em;">Preferred Pet is not valid</div>
            </div>

            <div class="mt-2">
                <label class="h3 text-white">Pet Size</label>
                <div class="d-flex" style="gap:10px 15px">
                    <div class="input-group" >
                        <select class="form-control" v-model="preferred_breed" style="background: transparent;border:unset;border-bottom: 1px solid white;border-radius: unset;">
                            <option value="all">All Size</option>
                            <option value="small">Small</option>
                            <option value="average">Average</option> 
                            <option value="large">Large</option> 
                        </select>
                    </div>
                </div>  
                <div class="badge badge-danger text-white" v-if="!valid.preferred_breed && isSubmit" style="margin-top: .5em;">Preferred Breed is not valid</div>
            </div>

            <div class="mt-2">
                <label class="h3 text-white">Notes</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-comment"></i></span>
                    <textarea class="form-control" name="inp[sha_bio]" placeholder="Note" v-model="note"></textarea>
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.note && isSubmit" style="margin-top: .5em;">Note not valid, atleast 8 character</div>
            </div>  

            <div class="mt-2">
                <label class="h3 text-white">Availability</label>
                <div class="d-flex" style="gap:10px 15px">
                    <div class="input-group" >
                        <select class="form-control" v-model="availability" style="background: transparent;border:unset;border-bottom: 1px solid white;border-radius: unset;">
                            <option value="n">Non Available</option>
                            <option value="y">Available</option> 
                        </select>
                    </div>
                </div>
            </div> 

            <form id="frm">
                <div class="mt-2">
                    <label class="h3 text-white">Prove of Identity</label>
                    <div class="input-group">
                        <span class="input-group-text text-white"><i class="bx bx-image"></i></span>
                        <input class="form-control" type="file" name="walker_docs" placeholder="Phone">
                    </div>
                    <div class="text-white" style="margin-top: .5em;">*This will be the document to verify your service</div>
                </div>
            </form>

            <div class="accordion mt-2" role="tablist">
                <b-card no-body class="mb-0">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="primary">Terms & Condition</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body style="max-height: 200px;overflow-y: scroll;">
                            <p class="">
                                Thank you for considering joining the Mongrela Pet Sitter community. Before embarking on this rewarding journey of caring for pets, please familiarize yourself with our terms and conditions. <br/> <br/>
                                Pet sitters must be at least 18 years old, and a comprehensive background check may be conducted during the application process. Once accepted, pet sitters commit to providing responsible and compassionate care, prioritizing the safety and well-being of the pets entrusted to them. Clear communication with pet owners, adherence to a professional code of conduct, and swift action in case of emergencies are paramount. Payment details and fees will be agreed upon directly with the pet owner, and while liability insurance is encouraged, it's not mandatory. Mongrela reserves the right to deny or terminate the application of any pet sitter at its discretion. By joining our community, you signify your understanding and agreement with these terms, contributing to the well-being of the pets we collectively care for. <br/> <br/>
                                We appreciate your dedication to the welfare of animals and look forward to having you as a valuable member of the Mongrela Pet Sitter community.
                            </p>
                        </b-card-body>
                    </b-collapse>
                </b-card> 
            </div>

            <div class="mt-2 w-100 d-flex flex-column justify-content-center" style="gap:10px">
                <button class="btn text-white flex-fill" style="background-color: #187294;height:50px;font-size: 18px;"
                    @click="onSubmit()">
                    Im Ready!
                </button>
            </div>

        </div>
    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie';
let cookies = new Cookies()

import {BCardHeader, BCard, BCollapse, BCardBody, BButton} from 'bootstrap-vue'

export default {
    components: {
        BCardHeader, BCard, BCollapse, BCardBody, BButton
    },
    computed: {
        user(){
            return store.state.auth.USER
        },
        walker(){
            return this.user?.walker
        },
        valid(){
            return {
                location : this.location?.length > 0, 
                preferred_pet : this.preferred_pet?.length > 0, 
                preferred_breed : this.preferred_breed?.length > 0, 
                note : this.note?.length > 15,  
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    watch: {
        walker: {
            handler: function(val){
                if(val){
                    this.location = val.walker_location
                    this.note = val.walker_note
                    this.preferred_pet = val.preferred_pet
                    this.preferred_breed = val.preferred_breed
                    this.availability = val.availability
                }
            }, immediate: true, deep: true
        }
    },
    methods: {
        onSubmit() { 
            this.isSubmit = true
            if (this.allValid) {
                let formData = new FormData($('#frm')[0])
                formData.append(`inp[user_id]`, cookies.get('token'))
                formData.append('inp[walker_location]', this.location)
                formData.append('inp[walker_note]', this.note)
                formData.append('inp[preferred_pet]', this.preferred_pet)
                formData.append('inp[preferred_breed]', this.preferred_breed)
                formData.append('inp[availability]', this.availability)

                store.dispatch('walker/WalkerSave', formData).then(() => {
                    store.dispatch('auth/GetUserData')
                })
            }
        }
    },
    data() {
        return {
            location: '',
            note: '',
            preferred_pet: 'dog',
            preferred_breed: 'all',
            availability: 'y',
            isSubmit: false,
        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus, textarea:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input, .input-group textarea {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 40px;
        color: white;
        background-color: transparent !important;
    } 

    select.form-control, select.form-control:active {
        color:white !important
    }
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>
